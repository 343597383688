import React, { useState, useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import Footer from '../components/Footer';
import Header from '../components/header/Header';
import { motion, AnimatePresence } from 'framer-motion';
import LaserAnimation from '../components/Laser';

const PageContainer = () => {
  const location = useLocation();
  const [loading, setLoading] = useState(true); // Start with loading state true
  const [showContent, setShowContent] = useState(false); // Controls when content is rendered
  const [allowContentAnimation, setAllowContentAnimation] = useState(false); // Controls when animation starts

  // Page transition variants
  const pageVariants = {
    initial: { opacity: 0, y: -20 },
    in: { opacity: 1, y: 0 },
    out: { opacity: 0, y: 20 },
  };

  const pageTransition = {
    duration: 0.6, // Transition duration
  };

  // Loading screen animation variants
  const loadingVariants = {
    initial: { opacity: 1 },
    exit: { opacity: 0, transition: { duration: 0.5 } }, // Fade out loading screen
  };

  // Trigger loading screen on route change
  useEffect(() => {
    setLoading(true); // Show loading screen on route change
    setShowContent(false); // Hide content while loading
    setAllowContentAnimation(false); // Prevent content animation until loading screen is gone

    const loadingTimer = setTimeout(() => {
      setLoading(false); // Hide loading screen after the delay
      setShowContent(true); // Show the page content after loading completes

      // Delay the start of content animations until the loading screen is completely gone
      const animationDelay = setTimeout(() => {
        setAllowContentAnimation(true);
      }, 250); // Match this with the loading exit animation duration

      return () => clearTimeout(animationDelay);
    }, 500); // Adjust timing based on your needs (matches the loading duration)

    return () => clearTimeout(loadingTimer); // Cleanup the timeout on unmount
  }, [location.pathname]);

  return (
    <div className="relative overflow-hidden">
      {/* AnimatePresence handles loading screen exit */}
      <AnimatePresence>
        {loading && (
          <motion.div
            className=" inset-0 flex items-center justify-center bg-black z-50 h-screen" // Ensure the loading screen is full screen
            key="loading-screen"
            initial="initial"
            animate="initial"
            exit="exit"
            variants={loadingVariants}
          >
           <img src='/images/logo/misura-blue.svg'/>
          </motion.div>
        )}
      </AnimatePresence>

      {/* Only render the page content when loading is complete */}
      {showContent && (
        <AnimatePresence mode="wait">
          {allowContentAnimation && (
            <>
              <Header />
              <motion.div
                key={location.pathname}
                initial="initial"
                animate="in"
                exit="out"
                variants={pageVariants}
                transition={pageTransition}
                className="overflow-x-hidden"
              >
                <div className="overflow-hidden">
                  <LaserAnimation />
                  <Outlet />
                </div>
              </motion.div>
              <Footer />
            </>
          )}
        </AnimatePresence>
      )}
    </div>
  );
};

export default PageContainer;
